import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import $ from "jquery";
import DataTable from "datatables.net";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL, BACKEND_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DashboardWidget} from "../../../_metronic/_partials/widgets/mixed/DashboardWidget";
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {date_format, datetime_format} from "../Common/DateFormat";
import Modal from 'react-bootstrap/Modal';
import {shallowEqual, useSelector} from "react-redux";
import {Line, Pie, Bar, Doughnut} from 'react-chartjs-2';
// import {DashboardWidget} from "../widgets/mixed/DashboardWidget.js";

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SVG from "react-inlinesvg";

export function Dashboard() {
    const [dashboard, setDashboard] = useState({
        pending_result: [],
        pending_verification: [],
        pending_verification: []
    });

    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [monthWiseProjects, setMonthWiseProjects] = useState({});
    const [doughnutChartData, setDoughnutChartData] = useState({});
    const [selected_filtered_duration, setSelectedFilteredDuration] = useState('this-week');

    const from = useRef();
    const to = useRef();
    const filtered_duration = useRef();

    const filter_durations = [
        {
            'code': 'today',
            'title': 'Today'
        },
        {
            'code': 'yesterday',
            'title': 'Yesterday'
        },
        {
            'code': 'this-week',
            'title': 'This Week'
        },
        {
            'code': 'last-week',
            'title': 'Last Week'
        },
        {
            'code': 'this-month',
            'title': 'This Month'
        },
        {
            'code': 'this-year',
            'title': 'This Year'
        },
        {
            'code': 'custom',
            'title': 'Custom'
        },
    ]
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,

        }),
        shallowEqual
    );

    const options = {
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true,
                    max: 1000,
                    min: 0
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: false,
                    min: 0
                }
            }]
        }
    };

    const doughnut_options = {
        rotation: Math.PI / 2,  // Rotate the chart to start from the left
        circumference: Math.PI * 2,  // Full circle
        cutout: '50%',  // Adjust if you want to change the size of the doughnut hole
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    const {authUser} = useSelector(
        ({auth}) => ({
            authUser: auth.user?.data,

        }),
        shallowEqual
    );

    const sendResults = () => {
        setDisabled(true);
        axios.get(
            API_URL + 'ukhsa/send-results'
        ).then((response) => {
            handleClose();
            setDisabled(true);
            if (response.data.status) {
                setShow(false);
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }

    // Animate the element's value from x to y:
    /*  $({someValue: 0}).animate({someValue: Math.floor(Math.random() * 3000)}, {
          duration: 3000,
          easing: 'swing', // can be anything
          step: function () { // called on every step
              // Update the element's text with rounded-up value:
              $('.count').text(commaSeparateNumber(Math.round(this.someValue)));
          }
      });

      function commaSeparateNumber(val) {
          while (/(\d+)(\d{3})/.test(val.toString())) {
              val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
          }
          return val;
      }*/

    const {t} = useTranslation();

    useEffect(() => {

    }, []);

    const updateChart = (data) => {

        let colors = ['#FFA800', '#6993FF', '#3dcc4a'];
        setMonthWiseProjects({
            labels: ['Registered', 'Received', 'Reported'],
            datasets: [
                {
                    label: 'All Samples',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: [colors[0], colors[1], colors[2]],
                    borderColor: colors[0],
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: colors[0],
                    pointBackgroundColor: colors[0],
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: colors[0],
                    pointHoverBorderColor: colors[0],
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 1,
                    data: [data.registered, data.received, data.reported]
                }
            ]
        });

        setDoughnutChartData({
            labels: ['Received', 'Reported'],
            datasets: [
                {
                    label: 'Samples',
                    data: [data.received, data.reported],
                    backgroundColor: [
                        '#6993FF',
                        '#3dcc4a',
                    ],
                    borderColor: [
                        '#6993FF',
                        '#3dcc4a',
                    ],
                    borderWidth: 1,
                },
            ],
        });
    }

    useEffect(() => {
        /*axios.get(API_URL + 'dashboard').then((response) => {
            if (response.data.status) {
                setDashboard(response.data.data);
                $(".stratprop_datatable").DataTable();
            } else {
                alertify.error("Error Occured");
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });*/

        let params = [
            {
                key: 'filtered_duration',
                value: selected_filtered_duration
            },
        ]
        getChartsData(params);

    }, []);

    const handleFilterChartsData = (e) => {
        e.preventDefault();
        setDisabled(1);

        console.log(filtered_duration);
        console.log(from);
        console.log(to);

        let params = [
            {
                key: 'filtered_duration',
                value: filtered_duration.current?.value
            },
            {
                key: 'from',
                value: from.current?.value
            },
            {
                key: 'to',
                value: to.current?.value
            },
        ];

        getChartsData(params);
    }

    const getChartsData = (params = false) => {
        let url = API_URL + 'dashboard/charts-data?';
        if (params) {
            for (let i in params) {
                url += '&' + params[i].key + '=' + params[i].value;
            }
        }
        axios.get(url).then((response) => {
            if (response.data.status) {
                updateChart(response.data.data);
                setDisabled(0);
            } else {
                alertify.error("Error Occured");
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });
    }
    const filterDurationHandler = (e) => {
        setSelectedFilteredDuration(e.target.value);
    }
    const filterChartsDataHandler = () => {

    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t(`Dashboard`)}>
                        </CardHeader>
                        <CardBody>
                            {/*<Row className={"custom-stats"}>
                                <Col md={12}>
                                    <h2>Status wise stats of patients count...</h2>
                                </Col>
                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="success"
                                        mainTitle={t("Registered")}
                                        value={dashboard.registration_status}
                                        icon="Custom/Layout-3d.svg"
                                    />
                                </Col>
                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="danger"
                                        mainTitle={t("Phlebotomy")}
                                        value={dashboard.phlebotomy_status}
                                        icon="Layout/Layout-4-blocks.svg"
                                    />
                                </Col>

                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="primary"
                                        mainTitle={t("Accession")}
                                        value={dashboard.accession_status}
                                        icon="Custom/Layout-3d.svg"
                                    />
                                </Col>

                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="warning"
                                        mainTitle={t("Initialized")}
                                        value={dashboard.initialized_status}
                                        icon="Layout/Layout-4-blocks.svg"
                                    />
                                </Col>
                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="success"
                                        mainTitle={t("Reported")}
                                        value={dashboard.reported_status}
                                        icon="Custom/Layout-3d.svg"
                                    />
                                </Col>
                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="danger"
                                        mainTitle={t("Final")}
                                        value={dashboard.final_status}
                                        icon="Layout/Layout-4-blocks.svg"
                                    />
                                </Col>

                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="primary"
                                        mainTitle={t("Verified")}
                                        value={dashboard.verified_status}
                                        icon="Custom/Layout-3d.svg"
                                    />
                                </Col>

                                <Col md={3}>
                                    <DashboardWidget
                                        className="gutter-b"
                                        symbolShape="circle"
                                        baseColor="warning"
                                        mainTitle={t("Delivered")}
                                        value={dashboard.delivered_status}
                                        icon="Layout/Layout-4-blocks.svg"
                                    />
                                </Col>

                            </Row>*/}
                            {/*End of row*/}
                            {
                                authUser?.role_id == 4 ? (
                                    <div className="row custom-stats">
                                        <div className="col-md-12">

                                            <div className={"row"}>
                                                <div className="col-6 col-md-4 quick-link-wrapper">
                                                    <div className={"quick-link quick-purple"}>
                                                        <Link to="/patient/create">
                                                            <div className={"quick-icon"}>
                                                                <i className="fa fa-users"/>
                                                            </div>
                                                            <div className={"quick-title"}>
                                                                Patient Registration
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                {/*End of quick-link*/}
                                                <div className="col-6 col-md-4 quick-link-wrapper">
                                                    <div className={"quick-link quick-darkblue"}>
                                                        <Link to="/patient/filter">
                                                            <div className={"quick-icon"}>
                                                                <i className="fa fa-filter"/>
                                                            </div>
                                                            <div className={"quick-title"}>
                                                                Patient Filter
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="col-6 col-md-4 quick-link-wrapper">
                                                    <div className={"quick-link quick-lightgreen"}>
                                                        <Link to="/get-unregistered-samples">
                                                            <div className={"quick-icon"}>
                                                                <i className="fa fa-list"/>
                                                            </div>
                                                            <div className={"quick-title"}>
                                                                Unregistered Samples
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Form onSubmit={handleFilterChartsData}>
                                            <div className="mt-5">

                                                <Form.Row>

                                                    <Form.Group as={Col} md={'2'} sm={"12"}>
                                                        <Form.Label>{t("Period")}</Form.Label>
                                                        <Form.Control as="select" ref={filtered_duration}
                                                                      value={selected_filtered_duration}
                                                                      onChange={filterDurationHandler}>
                                                            {filter_durations.length > 0 ? (
                                                                filter_durations.map((filter_solution, index) => (
                                                                    <option value={filter_solution.code}
                                                                            key={index}>{filter_solution.title}</option>
                                                                ))
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    {selected_filtered_duration == 'custom' ? (
                                                        <>
                                                            <Form.Group as={Col} md={'4'} sm={"12"}>
                                                                <Form.Label>{t("From")}</Form.Label>
                                                                <Form.Control type={"date"} ref={from}/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md={'4'} sm={"12"}>
                                                                <Form.Label>{t("To")}</Form.Label>
                                                                <Form.Control type={"date"} ref={to}/>
                                                            </Form.Group>
                                                        </>
                                                    ) : ('')}
                                                    <Form.Group as={Col} md={'1'} sm={"12"}>
                                                        <br/>

                                                        <Button variant="primary" className="float-right mt-2"
                                                                type="submit" disabled={disabled}>
                                                            {t("Filter")}
                                                            <span
                                                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                                        </Button>
                                                    </Form.Group>
                                                </Form.Row>

                                            </div>
                                        </Form>
                                        <div className="row custom-stats mb-5">

                                            <div className="col-12 col-md-6 quick-link-wrapper">
                                                <div className={""}>
                                                    <Bar data={monthWiseProjects} options={options}/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 quick-link-wrapper">
                                                <div className={""}>
                                                    <Doughnut data={doughnutChartData} options={doughnut_options}/>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row custom-stats">
                                            <div className="col-md-12">

                                                <div className={"row"}>
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-purple"}>
                                                            <Link to="/patient/create">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-users"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Patient Registration
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-darkblue"}>
                                                            <Link to="/patient/filter">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-filter"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Patient Filter
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-lightgreen"}>
                                                            <Link to="/patients-without-sample-received">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-mouse-pointer"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Receive Samples
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-purple"}>
                                                            <Link to="/patients-without-report">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-mouse-pointer"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Create Report
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-lightgreen"}>
                                                            <Link to="/patients-with-report">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-print"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Generate Report
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-darkblue"}>
                                                            <Link to="/summary/patient">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-file"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Patient Summary
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}
                                                    {/*<div className="col-6 col-md-4 quick-link-wrapper">
                                            <div className={"quick-link quick-purple"}>
                                                <Link to="/summary/patient-activity-log">
                                                    <div className={"quick-icon"}>
                                                        <i className="fa fa-users"/>
                                                    </div>
                                                    <div className={"quick-title"}>
                                                        Patient Activity Log
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>*/}
                                                    {/*<div className="col-6 col-md-4 quick-link-wrapper">
                      <div className={"quick-link quick-purple"}>
                        <Link to="/summary/patient-excel">
                          <div className={"quick-icon"}>
                            <i className="fa fa-users"/>
                          </div>
                          <div className={"quick-title"}>
                            Export Patient Report
                          </div>
                        </Link>
                      </div>
                    </div>*/}
                                                    {/*End of quick-link*/}
                                                    {/*<div className="col-6 col-md-4 quick-link-wrapper">
                      <div className={"quick-link quick-purple"} onClick={handleShow}>
                        <a href="#">
                          <div className={"quick-icon"}>
                            <i className="fa fa-list"/>
                          </div>
                          <div className={"quick-title"}>
                            Submit Results To UKHSA
                          </div>
                        </a>
                      </div>
                    </div>*/}
                                                    {/*End of quick-link*/}

                                                    {/*<div className="col-6 col-md-4 quick-link-wrapper">
                      <div className={"quick-link quick-purple"}>
                        <Link to="/item/create">
                          <div className={"quick-icon"}>
                            <i className="fa fa-plus-square"/>
                          </div>
                          <div className={"quick-title"}>
                            Add Item
                          </div>
                        </Link>
                      </div>
                    </div>*/}
                                                    {/*End of quick-link*/}
                                                    {/*<div className="col-6 col-md-4 quick-link-wrapper">
                      <div className={"quick-link quick-darkblue"}>
                        <Link to="/purchase/create">
                          <div className={"quick-icon"}>
                            <i className="fa  fa-shopping-basket"/>
                          </div>
                          <div className={"quick-title"}>
                            Add Purchase
                          </div>
                        </Link>
                      </div>
                    </div>*/}
                                                    {/*End of quick-link*/}
                                                    {/*<div className="col-6 col-md-4 quick-link-wrapper">
                      <div className={"quick-link quick-lightgreen"}>
                        <Link to="/item-consumption/create/consumption">
                          <div className={"quick-icon"}>
                            <i className="fa fa-minus-square"/>
                          </div>
                          <div className={"quick-title"}>
                            Add Consumption
                          </div>
                        </Link>
                      </div>
                    </div>*/}
                                                    {/*End of quick-link*/}
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-red"}>
                                                            <Link to="/patient/deleted-patients-filter">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-trash"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Deleted Patients List
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-lightblue"}>
                                                            <Link to="/patients-report-bulk-update">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-edit"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Bulk Report Update
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-darkblue"}>
                                                            <Link to="/create-bulk-auto-report-csv">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-save"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Generate Report From Machine CSV
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-purple"}>
                                                            <Link to="/create-immuno-report-csv">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-upload"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Upload Immuno CSV Results
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-lightgreen"}>
                                                            <Link to="/get-unregistered-samples">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-list"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Unregistered Samples
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-purple"}>
                                                            <Link to="/normal-ranges">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-cog"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Update Normal Ranges
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-darkblue"}>
                                                            <Link to="/test-details">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-paste"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Update Sub Tests
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-lightgreen"}>
                                                            <Link to="/summary/patient-excel">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-download"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Export Patients
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-darkblue"}>
                                                            <Link to="/test/list">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-save"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Update Tests
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-purple"}>
                                                            <Link to="/patient/pre-reg-samples">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-users"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Pre-reg Samples
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>


                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-lightgreen"}>
                                                            <Link to="/upload-pdf-result">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-upload"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Upload PDF Results
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4 quick-link-wrapper">
                                                        <div className={"quick-link quick-purple"}>
                                                            <Link to="/create-maglumi-report-csv">
                                                                <div className={"quick-icon"}>
                                                                    <i className="fa fa-upload"/>
                                                                </div>
                                                                <div className={"quick-title"}>
                                                                    Upload Maglumi CSV Results
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/*End of quick-link*/}
                                                </div>
                                            </div>


                                            {/*<div className="col-md-4 pt-10">
                                    <h4> Qucik Patient's Stats </h4>
                                    <div className="list-group">
                                        <a href="#" className="list-group-item visitor">
                                            <h3 className="pull-right">
                                                <i className="fa fa-users"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.today_patients}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Today Patients
                                            </p>
                                        </a>
                                        <a href="#" className="list-group-item facebook-like">
                                            <h3 className="pull-right">
                                                <i className="fa fa-users"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.weekly_patients}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Last 7 Days Patients
                                            </p>
                                        </a>
                                        <a href="#" className="list-group-item google-plus">
                                            <h3 className="pull-right">
                                                <i className="fa fa-users"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.monthly_patients}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Last 30 Days Patients
                                            </p>
                                        </a>
                                        <a href="#" className="list-group-item twitter">
                                            <h3 className="pull-right">
                                                <i className="fa fa-users"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.total_patients}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Total Patients
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 pt-10">
                                    <h4> Qucik Test's Stats </h4>
                                    <div className="list-group">
                                        <a href="#" className="list-group-item visitor">
                                            <h3 className="pull-right">
                                                <i className="fa fa-check-square"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.today_tests}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Today Test Count
                                            </p>
                                        </a>
                                        <a href="#" className="list-group-item facebook-like">
                                            <h3 className="pull-right">
                                                <i className="fa fa-check-square"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.weekly_tests}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Last 7 Days Test Count
                                            </p>
                                        </a>
                                        <a href="#" className="list-group-item google-plus">
                                            <h3 className="pull-right">
                                                <i className="fa fa-check-square"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.monthly_tests}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Last 30 Days Test Count
                                            </p>
                                        </a>
                                        <a href="#" className="list-group-item twitter">
                                            <h3 className="pull-right">
                                                <i className="fa fa-check-square"/>
                                            </h3>
                                            <h4 className="list-group-item-heading count">
                                                {dashboard.total_tests}
                                            </h4>
                                            <p className="list-group-item-text">
                                                Total Test Count
                                            </p>
                                        </a>
                                    </div>
                                </div>*/}
                                        </div>

                                    </>
                                )
                            }

                            {/*End of row*/}

                            {/*<div className="row custom-stats" style={{marginTop: '30px'}}>
                                <div className="col-md-12 ">
                                    <h4> Quick Earning's Stats </h4>
                                    <div className="row ">
                                        <div className="col-xl-3 col-lg-6">
                                            <div className="dashboard-card l-bg-cherry">
                                                <div className="card-statistic-3 p-4">
                                                    <div className="card-icon card-icon-large"><i
                                                        className="fas fa-shopping-cart"/></div>
                                                    <div className="mb-4">
                                                        <h5 className="card-title mb-0">Today</h5>
                                                    </div>
                                                    <div className="row align-items-center mb-2 d-flex">
                                                        <div className="col-8">
                                                            <h2 className="d-flex align-items-center mb-0">
                                                                $ {dashboard.today_income}
                                                            </h2>
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            <span>{dashboard.today_patients}</span>
                                                        </div>
                                                    </div>
                                                    <div className="progress mt-1 " data-height={8}
                                                         style={{height: '8px'}}>
                                                        <div className="progress-bar l-bg-cyan" role="progressbar"
                                                             data-width="25%" aria-valuenow={25} aria-valuemin={0}
                                                             aria-valuemax={100} style={{width: '25%'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-6">
                                            <div className="dashboard-card l-bg-blue-dark">
                                                <div className="card-statistic-3 p-4">
                                                    <div className="card-icon card-icon-large"><i
                                                        className="fas fa-users"/>
                                                    </div>
                                                    <div className="mb-4">
                                                        <h5 className="card-title mb-0">Past 7 Days</h5>
                                                    </div>
                                                    <div className="row align-items-center mb-2 d-flex">
                                                        <div className="col-8">
                                                            <h2 className="d-flex align-items-center mb-0">
                                                                $ {dashboard.weekly_income}
                                                            </h2>
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            <span>{dashboard.weekly_patients} </span>
                                                        </div>
                                                    </div>
                                                    <div className="progress mt-1 " data-height={8}
                                                         style={{height: '8px'}}>
                                                        <div className="progress-bar l-bg-green" role="progressbar"
                                                             data-width="25%" aria-valuenow={25} aria-valuemin={0}
                                                             aria-valuemax={100} style={{width: '25%'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-6">
                                            <div className="dashboard-card l-bg-green-dark">
                                                <div className="card-statistic-3 p-4">
                                                    <div className="card-icon card-icon-large"><i
                                                        className="fas fa-ticket-alt"/></div>
                                                    <div className="mb-4">
                                                        <h5 className="card-title mb-0">Past 30 Days</h5>
                                                    </div>
                                                    <div className="row align-items-center mb-2 d-flex">
                                                        <div className="col-8">
                                                            <h2 className="d-flex align-items-center mb-0">
                                                                $ {dashboard.monthly_income}
                                                            </h2>
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            <span>{dashboard.monthly_patients} </span>
                                                        </div>
                                                    </div>
                                                    <div className="progress mt-1 " data-height={8}
                                                         style={{height: '8px'}}>
                                                        <div className="progress-bar l-bg-orange" role="progressbar"
                                                             data-width="25%" aria-valuenow={25} aria-valuemin={0}
                                                             aria-valuemax={100} style={{width: '25%'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-6">
                                            <div className="dashboard-card l-bg-orange-dark">
                                                <div className="card-statistic-3 p-4">
                                                    <div className="card-icon card-icon-large"><i
                                                        className="fas fa-dollar-sign"/></div>
                                                    <div className="mb-4">
                                                        <h5 className="card-title mb-0">Total</h5>
                                                    </div>
                                                    <div className="row align-items-center mb-2 d-flex">
                                                        <div className="col-8">
                                                            <h2 className="d-flex align-items-center mb-0">
                                                                $ {dashboard.total_income}
                                                            </h2>
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            <span>{dashboard.total_patients} </span>
                                                        </div>
                                                    </div>
                                                    <div className="progress mt-1 " data-height={8}
                                                         style={{height: '8px'}}>
                                                        <div className="progress-bar l-bg-cyan" role="progressbar"
                                                             data-width="25%" aria-valuenow={25} aria-valuemin={0}
                                                             aria-valuemax={100} style={{width: '25%'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            {/*End of row*/}

                            {/*<Row className={"custom-stats"} style={{marginTop: "15px"}}>

                                <Col md={"6"} style={{overflow:"hidden"}}>
                                    <h3>Pending Results Patients List</h3>
                                    <table id="pending_results_table"
                                           className="stratprop_datatable table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>{t("SN.")}</th>
                                            <th>{t("Created At")}</th>
                                            <th>{t("MR.No")}</th>
                                            <th>{t("First Name")}</th>
                                            <th>{t("Last Name")}</th>
                                            <th>{t("Actions")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {dashboard.pending_result.length > 0 ? (
                                            dashboard.pending_result.map((patient, index) => (
                                                <tr key={`key${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{date_format(patient.registered_at)}</td>
                                                    <td>{patient.mr}</td>
                                                    <td>{patient.first_name}</td>
                                                    <td>{patient.last_name}</td>
                                                    <td>
                                                        <div>
                                                            <Link to={`/patient/edit/2`}
                                                                  className="btn-icon btn-light btn-hover-primary mx-3"
                                                                  title={'Edit Patient'}>
                                                                <EditIcon color={'primary'}></EditIcon>
                                                            </Link>
                                                            <a href={`${BACKEND_URL}invoice-print/2`}
                                                               target={'_blank'}
                                                               className="btn-icon btn-hover-warning mx-3">
                                                                <PrintIcon color="primary"></PrintIcon>
                                                            </a>
                                                            <Link to={`/report-create`}
                                                                  className="btn-icon btn-hover-warning mx-3">
                                                                <DescriptionIcon color="primary"></DescriptionIcon>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))
                                        ) : ("")}
                                        </tbody>
                                    </table>
                                </Col>


                                <Col md={"6"} style={{overflow: "hidden"}}>
                                    <h3>Pending Verification Patients List</h3>
                                    <table id="pending_results_table_"
                                           className="stratprop_datatable table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>{t("SN.")}</th>
                                            <th>{t("Created At")}</th>
                                            <th>{t("MR.No")}</th>
                                            <th>{t("First Name")}</th>
                                            <th>{t("Last Name")}</th>
                                            <th>{t("Actions")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {dashboard.pending_verification.length > 0 ? (
                                            dashboard.pending_verification.map((patient, index) => (
                                                <tr key={index + 1}>
                                                    <td>{index + 1}</td>
                                                    <td>{date_format(patient.registered_at)}</td>
                                                    <td>{patient.mr}</td>
                                                    <td>{patient.first_name}</td>
                                                    <td>{patient.last_name}</td>
                                                    <td>
                                                        <div>
                                                            <Link to={`/patient/edit/2`}
                                                                  className="btn-icon btn-light btn-hover-primary mx-3"
                                                                  title={'Edit Patient'}>
                                                                <EditIcon color={'primary'}></EditIcon>
                                                            </Link>
                                                            <a href={`${BACKEND_URL}invoice-print/2`}
                                                               target={'_blank'}
                                                               className="btn-icon btn-hover-warning mx-3">
                                                                <PrintIcon color="primary"></PrintIcon>
                                                            </a>
                                                            <Link to={`/report-create`}
                                                                  className="btn-icon btn-hover-warning mx-3">
                                                                <DescriptionIcon color="primary"></DescriptionIcon>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))
                                        ) : (
                                            <tr key={"nopatient"}>
                                                <td colSpan={6}>No Patient</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </Col>

                            </Row>*/}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Submit Results To UKHSA")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("Are you sure you want to Send the results exported to directory?")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={sendResults} disabled={disabled}>
                        {t("Confirm")}
                        <span
                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}