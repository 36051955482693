import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import {Form, Table, Button, Col, Row} from "react-bootstrap";
import alertify from "alertifyjs";
import {API_URL, BACKEND_URL} from "../constants.js";
import {AutoSuggest} from 'react-autosuggestions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card, CardBody, CardHeader, CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function CreatePreRegSample(props) {
    const {t} = useTranslation();
    const sample_id = useRef();
    const first_name = useRef();
    const last_name = useRef();
    const gender = useRef();
    const dob = useRef();
    const email = useRef();
    const phone_no = useRef();
    const address = useRef();
    const registered_at = useRef();
    const collection_datetime = useRef();
    const paid_amount = useRef();
    const due_amount = useRef();
    const [due, setDue] = useState(0);
    const reference_id = useRef();
    const collection_center_id = useRef();
    const postal_code = useRef();
    const passport_number = useRef();
    const date_arrived = useRef();
    const date_left = useRef();
    const nhs_number = useRef();
    const ethnicity = useRef();
    const vaccination_status = useRef();
    const phe_report_date = useRef();
    const title = useRef();
    // const royal_mail_tracking_no = useRef();

    const [test, setTest] = useState();
    const [references, setReference] = useState([]);
    const [collectionCenters, setCollectionCenter] = useState([]);
    const [patientTitle, setPatientTitle] = useState();
    const status = useRef();
    const [net_amount, setNetAmount] = useState(0);

    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    const [collection_datetime_current, setCollectionDateTimeCurrent] = useState(now.toISOString().slice(0, 16));

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const [options, setOptions] = useState(["HBsAg (screening)"]);
    const [letters, setLetters] = useState('');
    const [tests, setTests] = useState([]);
    const [sampleIdParam, setSampleIdParam] = useState('');


    useEffect(() => {
        axios.get(API_URL + 'patient/load-prerequisites').then((response) => {
            //console.log(response.data.references);
            if (response.data.status) {
                setReference(response.data.references);
                setCollectionCenter(response.data.collectioncenters);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        });


        const params = new URLSearchParams(window.location.search);
        if (params.get('sample_id')) setSampleIdParam(params.get('sample_id'));

    }, []);

    useEffect(() => {
        calculate();
    }, [tests]);

    const calculate = () => {
        var net_amount = 0;
        tests.map((element, index) => {
            net_amount += parseFloat(element.amount);
        });
        setNetAmount(net_amount);
        setDue(net_amount - paid_amount.current.value);
    };

    const handlePayment = () => {
        calculate();
    }

    const removeTest = (index) => {
        let arr = tests;
        arr = arr.filter((item) => item.name !== index);
        setTests(arr);
    }
    const handleAutoComplete = (letters) => {
        setLetters(letters);
        if (letters != '') {

            axios.post(API_URL + "search", {
                name: letters,
            }).then((response) => {
                // console.log(response.data);
                if (response.data.data.length > 0) {
                    var names = [];
                    response.data.data.map((item) => {
                        names.push(item.name);
                    });
                    setOptions(names);

                    // const regex = new RegExp(`\\b${letters}\\b`, 'i');
                    // var selectedIndex = names
                    //   .map((item, index) => (regex.test(item.trim()) ? index : -1)) // Trim spaces
                    //   .filter(index => index !== -1); // Remove non-matching indices;


                    var selectedIndex = response.data.data.findIndex(item => item.name === letters);
                    if (selectedIndex != -1) {

                        var selectedTest = {
                            id: response.data.data[selectedIndex].id,
                            name: response.data.data[selectedIndex].name,
                            amount: response.data.data[selectedIndex].amount,
                            turn_around_time: response.data.data[selectedIndex].turn_around_time,
                            sampletype: response.data.data[selectedIndex].sample_type.name
                        }
                        setTests([...tests, selectedTest]);
                        setLetters('');

                    }
                }
            }).catch((error) => {
                console.log('Nothing found');
            });

        }
    }

    const createPatientHandler = (e) => {

        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        //Tests array
        var testlist = [];
        tests.map((element, index) => {
            let temp = {
                "test_id": element.id
            }
            testlist.push(temp);
        });
        if (testlist.length == 0) {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Please select atleast one test");
            return false;
        }

        axios.post(API_URL + "patient/create-pre-reg-sample", {
            // title: title.current.value,
            // first_name: first_name.current.value,
            sample_id: sample_id.current.value,
            // last_name: last_name.current.value,
            // dob: dob.current.value,
            // gender: gender.current.value,
            // phone_no: phone_no.current.value,
            // email: email.current.value, // address: address.current.value,
            // postal_code: postal_code.current.value,
            // passport_number: passport_number.current.value,
            // royal_mail_tracking_no: royal_mail_tracking_no.current.value,
            // date_arrived: date_arrived.current.value,
            // date_left: date_left.current.value,
            // nhs_number: nhs_number.current.value,
            // ethnicity: ethnicity.current.value,
            // vaccination_status: vaccination_status.current.value,
            // phe_report_date: phe_report_date.current.value,
            // registered_at: registered_at.current.value,
            // collection_datetime: collection_datetime.current.value,
            collection_center_id: collection_center_id.current.value,
            // reference_id: reference_id.current.value,
            paid_amount: paid_amount.current.value,
            tests: testlist

        }).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status == true) {

                alertify.success(response.data.message);
                // window.open(`${BACKEND_URL}invoice-print/${response.data.patient_mr}`, '_blank').focus();
                // props.history.push("/patient/list");
            } else if (response.data.status == false) {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    return (

        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t("Create Pre-reg sample")}>
                        <CardHeaderToolbar>
                            <Link to="/patient/pre-reg-samples" className="btn btn-primary btn-sm mx-3">
                                {t("Pre-reg Samples")}
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={createPatientHandler}>
                            <div className="mt-5">
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Sample ID")}</Form.Label>
                                        <Form.Control defaultValue={sampleIdParam} ref={sample_id}
                                                      placeholder={t("Sample ID")}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Collection Center")}</Form.Label>
                                        <Form.Control as="select" ref={collection_center_id}>
                                            {collectionCenters.length > 0 ? (collectionCenters.map((collectioncenter, index) => (
                                                <option key={index}
                                                        value={collectioncenter.id}>{collectioncenter.name}</option>))) : (
                                                <option value={"0"}>{t("No Collection Center")}r</option>)}
                                        </Form.Control>
                                    </Form.Group>

                                    {/*<Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("Title")}</Form.Label>
                                        <Form.Control as="select" ref={title}>
                                            <option value="">{t("Not Stated")}</option>
                                            <option value="Mr.">{t("Mr.")}</option>
                                            <option value="Mrs.">{t("Mrs.")}</option>
                                            <option value="Miss.">{t("Miss.")}</option>
                                            <option value="Ms.">{t("Ms.")}</option>
                                            <option value="Dr.">{t("Dr.")}</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                    </Form.Group>*/}
                                </Form.Row>
                                {/*<Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("First Name")}</Form.Label>
                                        <Form.Control ref={first_name} placeholder={t("First Name")}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Last Name")}</Form.Label>
                                        <Form.Control ref={last_name} placeholder={t("Last Name")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Date Of Birth")}</Form.Label>
                                        <Form.Control type={"date"} ref={dob} placeholder={t("Date Of Birth")}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Gender")}</Form.Label>
                                        <Form.Control as="select" ref={gender} defaultValue={"Not Answered"}>
                                            <option value="male">{t("Male")}</option>
                                            <option value="female">{t("Female")}</option>
                                            <option value="na">{t("Not Answered")}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>*/}

                                {/*<Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Address")}</Form.Label>
                                        <Form.Control ref={address} placeholder={t("Address")}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Post Code")}</Form.Label>
                                        <Form.Control ref={postal_code} placeholder={t("Post Code")}/>
                                    </Form.Group>


                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Email")}</Form.Label>
                                        <Form.Control ref={email} placeholder={t("Email")}/>
                                    </Form.Group>*/}
                                    {/*<Form.Group as={Col}>
                    <Form.Label>{t("Passport Number")}</Form.Label>
                    <Form.Control ref={passport_number} placeholder={t("Passport Number")}/>
                  </Form.Group>*/}
                                    {/*<Form.Group as={Col}>
                                        <Form.Label>{t("Phone No")}</Form.Label>
                                        <Form.Control ref={phone_no} placeholder={t("Phone No")} defaultValue={"+44"}/>
                                    </Form.Group>*/}

                                    {/*<Form.Group as={Col}>
                    <Form.Label>{t("Royal Mail Tracking Number")}</Form.Label>
                    <Form.Control ref={royal_mail_tracking_no} placeholder={t("Royal Mail Tracking Number")}/>
                  </Form.Group>*/}

                                {/*</Form.Row>*/}
                                {/*<Form.Row>

                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("Date Arrived UK")}</Form.Label>
                                        <Form.Control ref={date_arrived} type={'date'}
                                                      placeholder={t("Date Arrived UK")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("Date Left")}</Form.Label>
                                        <Form.Control ref={date_left} type={'date'} placeholder={t("Date Left")}/>
                                    </Form.Group>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("NHS Number")}</Form.Label>
                                        <Form.Control ref={nhs_number} placeholder={t("NHS Number")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("Ethnicity")}</Form.Label>
                                        <Form.Control ref={ethnicity} placeholder={t("Ethnicity")}/>
                                    </Form.Group>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("Vaccination Status")}</Form.Label>
                                        <Form.Control ref={vaccination_status} placeholder={t("Vaccination Status")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("Date to report to PHE")}</Form.Label>
                                        <Form.Control ref={phe_report_date} type={'date'}
                                                      placeholder={t("Date to report to PHE")}/>
                                    </Form.Group>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("Registration Date")}</Form.Label>
                                        <Form.Control type={"datetime-local"} ref={registered_at}
                                                      placeholder={t("Registration Date")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Collection Date")}</Form.Label>
                                        <Form.Control type={"datetime-local"} ref={collection_datetime}
                                                      placeholder={t("Collection Date")}
                                                      defaultValue={collection_datetime_current}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Collection Center")}</Form.Label>
                                        <Form.Control as="select" ref={collection_center_id}>
                                            {collectionCenters.length > 0 ? (collectionCenters.map((collectioncenter, index) => (
                                                <option key={index}
                                                        value={collectioncenter.id}>{collectioncenter.name}</option>))) : (
                                                <option value={"0"}>{t("No Collection Center")}r</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("References")}</Form.Label>
                                        <Form.Control as="select" ref={reference_id}>
                                            {references.length > 0 ? (references.map((reference, index) => (
                                                <option key={index}
                                                        value={reference.id}>{reference.name}</option>))) : (
                                                <option value={"0"}>{t("No Reference")}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>*/}
                            </div>
                            <Row>
                                <Col md={8}>
                                    <AutoSuggest
                                        name="Test"
                                        handleChange={(e) => {
                                            handleAutoComplete(e);
                                        }}
                                        options={options}
                                        value={letters}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("Test Name")}</th>
                                            <th>{t("Sample")}</th>
                                            <th>{t("Turnaround Time")}</th>
                                            <th>{t("Amount")}</th>
                                            <td>{t("Action")}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tests.length > 0 ? (tests.map((element, index) => (<tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{element.name}</td>
                                            <td>{element.sampletype}</td>
                                            <td>{element.turn_around_time}</td>
                                            <td>{element.amount}</td>
                                            <td><a
                                                onClick={() => removeTest(element.name)}
                                                className="btn btn-icon btn-light btn-hover-danger btn-sm">
													<span className="svg-icon svg-icon-md svg-icon-danger">
													  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
													</span>
                                            </a>
                                            </td>
                                        </tr>))) : (<></>)}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={4}>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>{t("Net Amount")}</th>
                                            <th>{net_amount}</th>
                                        </tr>
                                        <tr>
                                            <th>{t("Paid")}</th>
                                            <th>
                                                <Form.Control ref={paid_amount} placeholder={t("Customer Paid")}
                                                              onKeyDown={handlePayment} onKeyUp={handlePayment}
                                                              onKeyPress={handlePayment} defaultValue={0}/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>{t("Due Amount")}</th>
                                            <th>
                                                <Form.Control ref={due_amount} defaultValue={due}
                                                              placeholder={t("Due Amount")}
                                                              onKeyDown={handlePayment} onKeyUp={handlePayment}
                                                              onKeyPress={handlePayment}/>
                                            </th>
                                        </tr>
                                        </thead>
                                    </Table>
                                </Col>
                            </Row>

                            <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                {t("Save")}
                                <span
                                    dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>);
}