import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import SettingsIcon from '@material-ui/icons/Settings';
import {API_URL} from '../constants.js';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";

export function TestDetailList(props) {
    const {t} = useTranslation();
    const [testDetails, setTestDetails] = useState([]);
    const [testDetail, setTestDetail] = useState(-1);
    const [testDetailObject, setTestDetailObject] = useState(-1);
    const [show, setShow] = useState(false);
    const [showShowHideModal, setShowShowHideModal] = useState(false);
    const history = useHistory();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [itemTypes, setItemTypes] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    // const alreadySelectedItems = {};



    const setCurrentTestDetail = (test_detail_id, type) => {
        // setTest(test);
        let test_detail = testDetails.find(td => td.id == test_detail_id);
        let alreadySelectedItems = {};
        setTestDetailObject(test_detail);

        if (test_detail.items && test_detail.items.length > 0) {
            for (let item of test_detail.items) {
                alreadySelectedItems[item.item.item_type_id] = item.item_id;
            }
        }

        setSelectedItems(alreadySelectedItems);

        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setShowShowHideModal(false);
    }

    const deleteTest = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'test/' + test + '/delete'
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    const showHideTest = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'test/' + test + '/show-hide'
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    const updateTestDetailField = (index, value, field) => {
        testDetails[index][field] = value;
        setTestDetails(testDetails);
    }

    const handleItemSelection = (item_type_id, value) => {
        selectedItems[item_type_id] = value;
    }

    const updateTestDetail = (index) => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'test/update-test-detail', testDetails[index]
        ).then((response) => {
            if (response.data.status) {
                // history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    const saveTestDetailItems = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'test/save-test-detail-items', {
                'id': testDetailObject.id,
                items: selectedItems
            }
        ).then((response) => {
            if (response.data.status) {
                // history.go(0);
                setShow(false);
                alertify.success(response.data.message);
                setDisabled(0);
                setIconDisabled(1);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    useEffect(() => {

        axios.get(API_URL + 'test/test-details').then((response) => {
            if (response.data.status) {

                setTestDetails(response.data.data);

                $("#test_table").DataTable();
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

        axios.get(API_URL + 'item-type/get-by-mode?mode=SUB_TEST').then((response) => {
            if (response.data.status) {

                setItemTypes(response.data.data);
                console.log(response.data.data);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

    }, []);


    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Test Detail List")}>
                            <CardHeaderToolbar>

                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody style={{overflow: 'auto'}}>

                            <table id="test_table" className="stratprop_datatable table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>{t("SN.")}</th>
                                    <th>{t("Sub Test / Test")}</th>
                                    <th>{t("Machine Code")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {testDetails.length > 0 ? (
                                    testDetails.map((test_detail, index) => (
                                        <tr key={test_detail.id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <strong>{test_detail.name}</strong>
                                                <br/>
                                                <span>{test_detail.test.name}</span>
                                            </td>

                                            <td>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Control defaultValue={test_detail.machine_code}
                                                                      onKeyUp={(e) => updateTestDetailField(index, e.target.value, 'machine_code')}/>
                                                    </Form.Group>
                                                </Form.Row>
                                            </td>

                                            <td>
                                                <a
                                                    onClick={() => updateTestDetail(index)}
                                                    key={"edit-test-" + test_detail.id}
                                                    className="btn btn-primary btn-hover-primary btn-sm mr-2">
                                                    Save
                                                </a>

                                                <a
                                                    onClick={() => setCurrentTestDetail(test_detail.id, 'assign-items')}
                                                    key={test_detail.id}
                                                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                    title={"Show on register page"}
                                                >
                                                    <SettingsIcon color={'primary'}></SettingsIcon>
                                                </a>
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8}>{t("No Test")}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Assign Items")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        {
                            itemTypes.length > 0 ? (
                                itemTypes.map((itemType, index) => (
                                    <Form.Group as={Col} md={'12'}>
                                        <Form.Label>{itemType.name}</Form.Label>
                                        <Form.Control as="select"
                                                      onChange={(e) => handleItemSelection(itemType.id, e.target.value)}
                                                      defaultValue={(selectedItems  && selectedItems[itemType.id] !== undefined) ? selectedItems[itemType.id] : "0"}
                                        >
                                            <option value={"0"}>{t("No Item")}</option>
                                            {itemType.items.length > 0 ? (
                                                itemType.items.map((item, index) => (
                                                    <option key={index}
                                                            value={item.id}>{item.name}</option>
                                                ))
                                            ) : (
                                                ''
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                ))
                            ) : ('')
                        }

                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={saveTestDetailItems} disabled={disabled}>
                        {t("Save")}
                        <span
                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}