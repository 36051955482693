/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {makeStyles} from '@material-ui/core/styles';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ExtensionIcon from '@material-ui/icons/Extension';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HelpIcon from '@material-ui/icons/Help';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PeopleIcon from '@material-ui/icons/People'; //User
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'; //Role
import {check_role} from '../../../../../app/pages/Common/RoleMenuCheck';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
}));

export function AsideMenuUserList({layoutProps}) {
    const classes = useStyles();
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {/* Users Management */}
                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">User Management </h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/* Users Manamgent */}
                {/*begin::1 Level*/}
                {check_role(['user/create', 'user/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/user", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/user">
            <span className="svg-icon menu-icon">
              <PeopleIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">User Management</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">User Management</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['user/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/user/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/user/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Create</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['user/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/user/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/user/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
            {/*    {check_role(['role/create', 'role/list']) ? (*/}
            {/*        <li*/}
            {/*            className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
            {/*                "/role", true*/}
            {/*            )}`}*/}
            {/*            aria-haspopup="true"*/}
            {/*            data-menu-toggle="hover"*/}
            {/*        >*/}
            {/*            <NavLink className="menu-link menu-toggle" to="/role">*/}
            {/*<span className="svg-icon menu-icon">*/}
            {/*  <AllInclusiveIcon className={classes.icon}/>*/}
            {/*</span>*/}
            {/*                <span className="menu-text">Roles Management</span>*/}
            {/*                <i className="menu-arrow"/>*/}
            {/*            </NavLink>*/}
            {/*            <div className="menu-submenu ">*/}
            {/*                <i className="menu-arrow"/>*/}
            {/*                <ul className="menu-subnav">*/}
            {/*                    <li className="menu-item  menu-item-parent" aria-haspopup="true">*/}
            {/*    <span className="menu-link">*/}
            {/*      <span className="menu-text">Roles Management</span>*/}
            {/*    </span>*/}
            {/*                    </li>*/}

            {/*                    /!*begin::2 Level*!/*/}
            {/*                    {check_role(['role/create']) ? (*/}
            {/*                        <li*/}
            {/*                            className={`menu-item ${getMenuItemActive("/role/create")}`}*/}
            {/*                            aria-haspopup="true"*/}
            {/*                        >*/}
            {/*                            <NavLink className="menu-link" to="/role/create">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot">*/}
            {/*                                    <span/>*/}
            {/*                                </i>*/}
            {/*                                <span className="menu-text">Create</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                    ) : ''}*/}
            {/*                    /!*end::2 Level*!/*/}

            {/*                    /!*begin::2 Level*!/*/}
            {/*                    {check_role(['role/list']) ? (*/}
            {/*                        <li*/}
            {/*                            className={`menu-item ${getMenuItemActive("/role/list")}`}*/}
            {/*                            aria-haspopup="true"*/}
            {/*                        >*/}
            {/*                            <NavLink className="menu-link" to="/role/list">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot">*/}
            {/*                                    <span/>*/}
            {/*                                </i>*/}
            {/*                                <span className="menu-text">List</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                    ) : ''}*/}
            {/*                    /!*end::2 Level*!/*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </li>*/}
            {/*    ) : ''}*/}
                {/*end::1 Level*/}


                {/*begin::1 Level*/}
            {/*    {check_role(['menu/create', 'menu/list', 'menu/roles']) ? (*/}
            {/*        <li*/}
            {/*            className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
            {/*                "/menu", true*/}
            {/*            )}`}*/}
            {/*            aria-haspopup="true"*/}
            {/*            data-menu-toggle="hover"*/}
            {/*        >*/}
            {/*            <NavLink className="menu-link menu-toggle" to="/role">*/}
            {/*<span className="svg-icon menu-icon">*/}
            {/*  <AllInclusiveIcon className={classes.icon}/>*/}
            {/*</span>*/}
            {/*                <span className="menu-text">Role Menu Access</span>*/}
            {/*                <i className="menu-arrow"/>*/}
            {/*            </NavLink>*/}
            {/*            <div className="menu-submenu ">*/}
            {/*                <i className="menu-arrow"/>*/}
            {/*                <ul className="menu-subnav">*/}
            {/*                    <li className="menu-item  menu-item-parent" aria-haspopup="true">*/}
            {/*    <span className="menu-link">*/}
            {/*      <span className="menu-text">Role Menu Access</span>*/}
            {/*    </span>*/}
            {/*                    </li>*/}
            
            {/*                    /!*begin::2 Level*!/*/}
            {/*                    {check_role(['menu/create']) ? (*/}
            {/*                        <li*/}
            {/*                            className={`menu-item ${getMenuItemActive("/menu/create")}`}*/}
            {/*                            aria-haspopup="true"*/}
            {/*                        >*/}
            {/*                            <NavLink className="menu-link" to="/menu/create">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot">*/}
            {/*                                    <span/>*/}
            {/*                                </i>*/}
            {/*                                <span className="menu-text">Create Menu</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                    ) : ''}*/}
            {/*                    /!*end::2 Level*!/*/}
            
            {/*                    /!*begin::2 Level*!/*/}
            {/*                    {check_role(['menu/list']) ? (*/}
            {/*                        <li*/}
            {/*                            className={`menu-item ${getMenuItemActive("/menu/list")}`}*/}
            {/*                            aria-haspopup="true"*/}
            {/*                        >*/}
            {/*                            <NavLink className="menu-link" to="/role/list">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot">*/}
            {/*                                    <span/>*/}
            {/*                                </i>*/}
            {/*                                <span className="menu-text">Menu List</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                    ) : ''}*/}
            {/*                    /!*end::2 Level*!/*/}
            {/*                    /!*begin::2 Level*!/*/}
            {/*                    {check_role(['menu/roles']) ? (*/}
            {/*                        <li*/}
            {/*                            className={`menu-item ${getMenuItemActive("/menu/roles")}`}*/}
            {/*                            aria-haspopup="true"*/}
            {/*                        >*/}
            {/*                            <NavLink className="menu-link" to="/menu/roles">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot">*/}
            {/*                                    <span/>*/}
            {/*                                </i>*/}
            {/*                                <span className="menu-text">Role Menu Setup</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                    ) : ''}*/}
            {/*                    /!*end::2 Level*!/*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </li>*/}
            {/*    ) : ''}*/}
                {/*end::1 Level*/}


            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
