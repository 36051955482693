import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function EditItem(props) {
    const {t} = useTranslation();
    const name = useRef();
    const price = useRef();
    const max_stock = useRef();
    const min_stock = useRef();
    const pieces = useRef();
    const notes = useRef();
    const item_unit_id = useRef();
    const item_type_id = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [item, setItem] = useState({});
    const id = props.match.params.id;
    const [itemUnits, setItemUnits] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    const [fieldUnit, setFieldsUnit] = useState({
        'item_unit_id': ''
    })
    const [fieldType, setFieldsType] = useState({
        'item_type_id': ''
    })

    useEffect(() => {
        axios.get(API_URL + 'item-unit').then((response) => {

            if (response.data.status) {
                setItemUnits(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);

    useEffect(() => {
        axios.get(API_URL + 'item-type').then((response) => {

            if (response.data.status) {
                setItemTypes(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);

    useEffect(() => {
        axios.get(API_URL + 'item/' + id).then((response) => {
            if (response.data.status) {
                setItem(response.data.data);
                setFieldsUnit({item_unit_id: response.data.data.item_unit_id});

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);

    useEffect(() => {
        axios.get(API_URL + 'item/' + id).then((response) => {
            if (response.data.status) {
                setItem(response.data.data);
                setFieldsType({item_type_id: response.data.data.item_type_id});

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);

    const updateItemHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "item/update",
            {
                id: item.id,
                name: name.current.value,
                price: price.current.value,
                max_stock: max_stock.current.value,
                min_stock: min_stock.current.value,
                pieces: pieces.current.value,
                notes: notes.current.value,
                item_unit_id: item_unit_id.current.value,
                item_type_id: item_type_id.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/item/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    const handleunitChange = (e) => {
        setFieldsUnit({
            item_unit_id: e.target.value
        });
    }
    const handletypeChange = (e) => {
        setFieldsType({
            item_type_id: e.target.value
        });
    }


    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t("Edit Item")}>
                        <CardHeaderToolbar>
                            <Link to="/item/list" className="btn btn-primary btn-sm mx-3">
                                {t("Item List")}
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>

                        <div className="mt-5">
                            <Form onSubmit={updateItemHandler}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Item Unit")}</Form.Label>
                                        <Form.Control as="select" ref={item_unit_id} value={fieldUnit.item_unit_id}
                                                      onChange={handleunitChange}>
                                            <option value="">{t("Select Item Unit")}</option>
                                            {itemUnits.map((itemUnit, key) => (
                                                <option key={itemUnit.id} value={itemUnit.id}>
                                                    {itemUnit.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Item Type")}</Form.Label>
                                        <Form.Control as="select" ref={item_type_id} value={fieldType.item_type_id}
                                                      onChange={handletypeChange}>
                                            <option value="">{t("Select Item Type")} </option>
                                            {itemTypes.map((itemType, key) => (
                                                <option key={itemType.id} value={itemType.id}>
                                                    {itemType.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Name")}</Form.Label>
                                        <Form.Control ref={name} placeholder={t("Enter Item Name")}
                                                      defaultValue={item.name}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Price")}</Form.Label>
                                        <Form.Control ref={price} as={"number"} placeholder={t("Enter Price")}
                                                      defaultValue={item.price}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Max Stock")}</Form.Label>
                                        <Form.Control ref={max_stock} as={"number"} placeholder={t("Max Stock")}
                                                      defaultValue={item.max_stock}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Min Stock")}</Form.Label>
                                        <Form.Control ref={min_stock} as={"number"} placeholder={t("Enter Min Stock")}
                                                      defaultValue={item.min_stock}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Units")}</Form.Label>
                                        <Form.Control ref={pieces} as={"number"} placeholder={t("Enter Units")}
                                                      defaultValue={item.pieces}/>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Notes")}</Form.Label>
                                        <Form.Control as={'text'} ref={notes} placeholder={t("Notes")}
                                                      defaultValue={item.notes}/>
                                    </Form.Group>
                                </Form.Row>

                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}