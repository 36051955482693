import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import {API_URL, BACKEND_URL} from '../constants.js';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EmailIcon from '@material-ui/icons/Email';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";

export function PatientsWithReport(props) {
    const {t} = useTranslation();
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState(null);
    const [show, setShow] = useState(false);
    const [passwordVerified, setPasswordVerified] = useState(false);
    const [action, setAction] = useState('patient');
    const history = useHistory();
    const [disabled, setDisabled] = useState(0);
    const [checkedItems, setCheckedItems] = useState({});

    const password = useRef();

    const setCurrentPatient = (patient_id, action) => {
        setPatient(patient_id);

        if (action == 'mail') {
            sendSingleEmail(patient_id);
            /*if (passwordVerified) {
                sendSingleEmail();
            } else {
                setShow(true);
            }*/
        }
    };

    const handleClose = () => setShow(false);

    const deletePatient = () => {
        axios.post(
            API_URL + 'patient/delete',
            {
                id: patient,
                action: action
            }
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }

    useEffect(() => {
        if (!passwordVerified) {
            setShow(true);
        }
    }, []);

    const getPatientsWithReport = () => {
        axios.get(API_URL + 'patients-with-report').then((response) => {
            if (response.data.status) {

                setPatients(response.data.data);

                $("#patient_table").DataTable();
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
            .catch((error) => {

                if (error.message === 'Request failed with status code 401') {
                    //props.logout();
                }
            });
    }

    const handleCheckboxChange = (event, id) => {
        setCheckedItems({
            ...checkedItems,
            [id]: event.target.checked
        });

    };

    useEffect(() => {
        console.log("items: ", checkedItems);
    }, [checkedItems])

    const sendBulkEmail = () => {
        setDisabled(1);
        axios.post(
            API_URL + 'patient/send-mail',
            {patients: checkedItems}
        ).then((response) => {
            setDisabled(0);
            if (response.data.status) {
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        });
    }

    const sendSingleEmail = (patient_id) => {
        setDisabled(1);
        axios.post(
            API_URL + 'patient/send-mail',
            {id: patient_id}
        ).then((response) => {
            setDisabled(0);
            if (response.data.status) {
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        });
    }

    const toggleSelectAllPatients = (event) => {
        let selected_patients = {};
        for (let p in patients) {
            selected_patients[patients[p].id] = event.target.checked;
        }

        setCheckedItems(selected_patients);
    }

    const verifyPassword = (e) => {
        e.preventDefault();
        setDisabled(1);

        axios.post(
            API_URL + 'user/verify-password',
            {
                password: password.current.value
            }
        ).then((response) => {
            setDisabled(0);
            if (response.data.status) {
                setShow(false);
                setPasswordVerified(true);
                getPatientsWithReport();
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Reports To Send Email")}>
                            <CardHeaderToolbar>
                                <Button variant="primary" onClick={sendBulkEmail} disabled={disabled}>
                                    Send
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody style={{overflow: 'auto'}}>
                            <table id="patient_table"
                                   className="stratprop_datatable table table-striped table-bordered">
                                <thead>
                                <tr key={'thead'}>
                                    <th>
                                        <input type="checkbox"
                                               onChange={(e) => {
                                                   toggleSelectAllPatients(e)
                                               }}/>
                                    </th>
                                    <th>{t("SN.")}</th>
                                    <th>{t("Actions")}</th>
                                    <th>{t("Mr.No")}</th>
                                    <th>{t("Sample ID")}</th>
                                    <th>{t("Name")}</th>
                                    <th style={{width: '250px'}}>{t("Test Type")}</th>
                                    {/*<th>{t("Result")}</th>*/}
                                    <th>{t("Registered at")}</th>
                                    <th>{t("Gender / DOB")}</th>
                                    <th>{t("Collection Date Time")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {patients.length > 0 ? (
                                    patients.map((patient, index) => (
                                        <tr key={index + 1}>
                                            <td>
                                                <Form.Check
                                                    key={patient.id}
                                                    type={'checkbox'}
                                                    id={`checkbox-${index}`}
                                                    checked={checkedItems[patient.id] || false}
                                                    onChange={(e) => {
                                                        handleCheckboxChange(e, patient.id)
                                                    }}
                                                    value={""}
                                                />

                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <a
                                                    disabled={disabled}
                                                    title={"Send Email"}
                                                    onClick={() => setCurrentPatient(patient.id, 'mail')}
                                                    key={index}
                                                    className="btn-icon btn-light btn-hover-danger mx-3">
                                                    <EmailIcon color="secondary"></EmailIcon>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                                </a>
                                                <a href={`${BACKEND_URL}report-print/${patient.mr}`}
                                                   target={'_blank'}
                                                   className="btn-icon btn-hover-warning mx-3"
                                                   title={"Print Report"}>
                                                    <DescriptionIcon color="primary"></DescriptionIcon>
                                                </a>
                                            </td>
                                            <td>{patient.mr}</td>
                                            <td>
                                                {patient.sample_id}
                                                <br/>
                                                <span
                                                    className={`badge badge-primary`}>{patient.report_status.name}</span>
                                            </td>
                                            <td>
                                                <span className={'font-bold'}>{patient.first_name}</span>
                                                <br/>
                                                <span>{patient.last_name}</span>
                                                <br/>
                                                <a className={`font-bold`}
                                                   href={`mailto:${patient.email}`}>{patient.email}</a>
                                            </td>
                                            {/*<td>
                        <ul>
                          {patient.patient_tests.length > 0 ? (
                            patient.patient_tests.map((patient_test, index) => (
                              <li>{patient_test.test?.name}</li>
                            ))
                          ) : ''
                          }
                        </ul>
                      </td>*/}
                                            <td>
                                                {patient.result_data.length > 0 ? (
                                                    patient.result_data.map((test, index) => (
                                                        <>
                                                            <strong>{test.name}</strong>
                                                            {test.details.length > 0 ? (
                                                                <ul>
                                                                    {test.details.map((detail, index) => (
                                                                        <li className={`result-` + detail.color}
                                                                            key={index}>{detail.name}: {detail.result}</li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <br/>
                                                            )}
                                                        </>
                                                    ))
                                                ) : (
                                                    ''
                                                )}
                                            </td>

                                            <td>
                                                {(patient.registered_at) ? datetime_format(patient.registered_at) : ""}

                                            </td>
                                            {/*<td>{(patient.collection_datetime) ? datetime_format(patient.collection_datetime) : ""}</td>*/}
                                            <td>
                                                <span className={`font-bold`}>{patient.gender}</span>
                                                <br/>
                                                <span>{date_format(patient.dob)}</span>
                                            </td>
                                            {/*<td>{patient.phone_no}</td>*/}
                                            <td>
                                                {(patient.collection_datetime) ? datetime_format(patient.collection_datetime) : ""}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr key={"nopatient"}>
                                        <td colSpan={13}>No Patient</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={verifyPassword}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Password Verification")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"mt-5"}>
                            <Row className={"mb-10 "}>
                                <Form.Group as={Col}>
                                    <Form.Label>{t("Enter Password")}</Form.Label>
                                    <Form.Control type={"password"} ref={password}
                                                  placeholder={t("password")}/>
                                </Form.Group>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t("Close")}
                        </Button>
                        <Button variant="primary" disabled={disabled} type="submit">
                            Verify
                            <span
                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

