import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route, BrowserRouter} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
// import {DashboardPage} from "./pages/DashboardPage";
import {Dashboard} from "./pages/Dashboard/Dashboard";
// import {Users} from "./pages/Users/Users";
// import {UserDetail} from "./pages/Users/UserDetail";
// import {UpdateAccount} from "./pages/Users/UpdateAccount";
// import {EditUser} from "./pages/Users/EditUser";

import {CreateUnit} from "./pages/Unit/CreateUnit";
import {UnitList} from "./pages/Unit/UnitList";
import {UnitEdit} from "./pages/Unit/UnitEdit";

import {CreateAntibiotic} from "./pages/Antibiotics/CreateAntibiotic";
import {AntibioticList} from "./pages/Antibiotics/AntibioticList";
import {AntibioticEdit} from "./pages/Antibiotics/AntibioticEdit";

import {CreatePatient} from "./pages/Patient/CreatePatient";
import {CreatePreRegSample} from "./pages/Patient/CreatePreRegSample";
import {PreRegSamples} from "./pages/Patient/PreRegSamples";

import {PatientList} from "./pages/Patient/PatientList";
import {PatientEdit} from "./pages/Patient/PatientEdit";

import {TestCreate} from "./pages/Test/TestCreate";
import {TestList} from "./pages/Test/TestList";
import {TestDetailList} from "./pages/Test/TestDetailList";
import {TestDetailNormalRangesList} from "./pages/Test/TestDetailNormalRangesList";
import {TestEdit} from "./pages/Test/TestEdit";

import {CreateTestCategory} from "./pages/TestCategory/CreateTestCategory";
import {TestCategoryList} from "./pages/TestCategory/TestCategoryList";
import {EditTestCategory} from "./pages/TestCategory/EditTestCategory";

import {CreateReference} from "./pages/Reference/CreatReference";
import {ReferenceList} from "./pages/Reference/ReferenceList";
import {EditReference} from "./pages/Reference/EditReference";

import {CreateSampleType} from "./pages/SampleType/CreateSampleType";
import {SampleTypeList} from "./pages/SampleType/SampleTypeList";
import {EditSampleType} from "./pages/SampleType/EditSampleType";

import {CreateCollectionCenter} from "./pages/CollectionCenter/CreateCollectionCenter";
import {CollectionCenterList} from "./pages/CollectionCenter/CollectionCenterList";
import {EditCollectionCenter} from "./pages/CollectionCenter/EditCollectionCenter";

import {PatientFilter} from "./pages/Patient/PatientFilter";
import {DeletedPatientFilter} from "./pages/Patient/DeletedPatientFilter";

import {ContactList} from "./pages/Contacts/ContactList";

import {CreateSupplier} from "./pages/Supplier/CreateSupplier";
import {SupplierList} from "./pages/Supplier/SupplierList";
import {EditSupplier} from "./pages/Supplier/EditSupplier";

import {CreateItemType} from "./pages/ItemType/CreateItemType";
import {ItemTypeList} from "./pages/ItemType/ItemTypeList";
import {EditItemType} from "./pages/ItemType/EditItemType";

import {CreateItemUnit} from "./pages/ItemUnit/CreateItemUnit";
import {ItemUnitList} from "./pages/ItemUnit/ItemUnitList";
import {EditItemUnit} from "./pages/ItemUnit/EditItemUnit";

import {CreateItem} from "./pages/Items/CreateItem";
import {ItemList} from "./pages/Items/ItemList";
import {EditItem} from "./pages/Items/EditItem";

import {CreateRole} from "./pages/Role/CreateRole";
import {RoleList} from "./pages/Role/RoleList";
import {EditRole} from "./pages/Role/EditRole";

import {PurchaseCreate} from "./pages/Purchse/PurchaseCreate";
import {PurchaseList} from "./pages/Purchse/PurchaseList";
import {PurchaseEdit} from "./pages/Purchse/PurchaseEdit";

import {CreateUser} from "./pages/Users/CreateUser";
import {UserList} from "./pages/Users/UserList";
import {EditUser} from "./pages/Users/EditUser";

import {CreateReportRemarks} from "./pages/ReportRemarks/CreateReportRemarks";
import {ReportRemarksList} from "./pages/ReportRemarks/ReportRemarksList";
import {EditReportRemarks} from "./pages/ReportRemarks/EditReportRemarks";

import {ReportCreate} from "./pages/Reports/ReportCreate";
import {ReportEdit} from "./pages/Reports/ReportEdit";
import {ReportGenerate} from "./pages/Reports/ReportGenerate";
import {ReportUpdate} from "./pages/Reports/ReportUpdate";
/*import Invoice from "./pages/Patient/Invoice"; //For Class based components
import Report from "./pages/Reports/Report"; //For Class based components*/

import {EditLab} from "./pages/Labs/EditLab";

import {CreateTransactionCategory} from "./pages/TransactionCategory/CreateTransactionCategory";
import {TransactionCategoryList} from "./pages/TransactionCategory/TransactionCategoryList";
import {EditTransactionCategory} from "./pages/TransactionCategory/EditTransactionCategory";

import {CreateTransaction} from "./pages/Transaction/CreateTransaction";
import {TransactionList} from "./pages/Transaction/TransactionList";
import {EditTransaction} from "./pages/Transaction/EditTransaction";
import {CreateItemConsumption} from "./pages/ItemConsumption/CreateItemConsumption";
import {ItemConsumptionList} from "./pages/ItemConsumption/ItemConsumptionList";

/*Admin Summaries Section*/
import {Patient} from "./pages/Summaries/Patient";
import {PatientsWithoutReport} from "./pages/Patient/PatientsWithoutReport";
import {PatientsWithoutSampleReceived} from "./pages/Patient/PatientsWithoutSampleReceived";
import {PatientsWithReport} from "./pages/Patient/PatientsWithReport";
import {PatientsReportBulkUpdate} from "./pages/Patient/PatientsReportBulkUpdate";
import {CreateBulkAutoReportCsv} from "./pages/Patient/CreateBulkAutoReportCsv";
import {CreateImmunoReportCsv} from "./pages/Patient/CreateImmunoReportCsv";
import {CreateMaglumiReportCsv} from "./pages/Patient/CreateMaglumiReportCsv";
import {UploadPdfResult} from "./pages/Patient/UploadPdfResult";
import {UnregisteredSamples} from "./pages/Patient/UnregisteredSamples";
import {Reference} from "./pages/Summaries/Reference";
import {Status} from "./pages/Summaries/Status";
import {DueAmount} from "./pages/Summaries/DueAmount";
import {RateList} from "./pages/Summaries/RateList";
import {PatientActivityLog} from "./pages/Summaries/PatientActivityLog";
import {PurchaseReport} from "./pages/Summaries/PurchaseReport";
import {PurchaseSummary} from "./pages/Summaries/PurchaseSummary";
import {TestCount} from "./pages/Summaries/TestCount";
import {Income} from "./pages/Summaries/Income";
import {Expense} from "./pages/Summaries/Expense";
import {PatientExcel} from "./pages/Summaries/PatientExcel";

import {FaqCreate} from "./pages/Faqs/FaqCreate";
import {FaqList} from "./pages/Faqs/FaqList";
import {FaqEdit} from "./pages/Faqs/FaqEdit";

import {Mypdf} from "./pages/ItemConsumption/Mypdf";
import {UmairHatePdf} from "./pages/ItemConsumption/UmairHatePdf";

import {MenuCreate} from "./pages/Menu/MenuCreate";
import {MenuList} from "./pages/Menu/MenuList";
import {MenuEdit} from "./pages/Menu/MenuEdit";
import {MenuRoleEdit} from "./pages/MenuRole/MenuRoleEdit";
import {UploadPreRegSamplesCsv} from "./pages/Patient/UploadPreRegSamplesCsv";

/*const GoogleMaterialPage = lazy(() =>



const GoogleMaterialPage = lazy(() =>
    import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
    import("./modules/ECommerce/pages/eCommercePage")
);*/

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    console.log(process.env);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={Dashboard}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                {/*<Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>*/}
                {/*<Route path="/user/all" component={Users}/>*/}
                {/*<Route path="/user/detail/:id" component={UserDetail}/>*/}
                {/*<Route path="/user/update-account" component={UpdateAccount}/>*/}
                {/*<Route path="/user/edit/:id" component={EditUser}/>*/}

                <Route path="/unit/create" component={CreateUnit}/>
                <Route path="/unit/list" component={UnitList}/>
                <Route path="/unit/edit/:id" component={UnitEdit}/>
                <Route path="/patient/create" component={CreatePatient}/>
                <Route path="/patient/create-pre-reg-sample" component={CreatePreRegSample}/>
                <Route path="/patient/upload-pre-reg-samples-csv" component={UploadPreRegSamplesCsv}/>
                <Route path="/patient/pre-reg-samples" component={PreRegSamples}/>
                <Route path="/patient/list" component={PatientList}/>
                <Route path="/patient/edit/:id" component={PatientEdit}/>

                <Route path="/antibiotic/create" component={CreateAntibiotic}/>
                <Route path="/antibiotic/list" component={AntibioticList}/>
                <Route path="/antibiotic/edit/:id" component={AntibioticEdit}/>

                <Route path="/test-category/create" component={CreateTestCategory}/>
                <Route path="/test-category/list" component={TestCategoryList}/>
                <Route path="/test-category/edit/:id" component={EditTestCategory}/>

                <Route path="/test/create" component={TestCreate}/>
                <Route path="/test/list" component={TestList}/>
                <Route path="/test/edit/:id" component={TestEdit}/>
                <Route path="/test-details" component={TestDetailList}/>
                <Route path="/normal-ranges" component={TestDetailNormalRangesList}/>

                <Route path="/reference/create" component={CreateReference}/>
                <Route path="/reference/list" component={ReferenceList}/>
                <Route path="/reference/edit/:id" component={EditReference}/>

                <Route path="/sample-type/create" component={CreateSampleType}/>
                <Route path="/sample-type/list" component={SampleTypeList}/>
                <Route path="/sample-type/edit/:id" component={EditSampleType}/>

                <Route path="/collection-center/create" component={CreateCollectionCenter}/>
                <Route path="/collection-center/list" component={CollectionCenterList}/>
                <Route path="/collection-center/edit/:id" component={EditCollectionCenter}/>

                <Route path="/patient/filter" component={PatientFilter}/>
                <Route path="/patient/deleted-patients-filter" component={DeletedPatientFilter}/>

                <Route path="/contact/list" component={ContactList}/>

                <Route path="/supplier/create" component={CreateSupplier}/>
                <Route path="/supplier/list" component={SupplierList}/>
                <Route path="/supplier/edit/:id" component={EditSupplier}/>

                <Route path="/item-type/create" component={CreateItemType}/>
                <Route path="/item-type/list" component={ItemTypeList}/>
                <Route path="/item-type/edit/:id" component={EditItemType}/>

                <Route path="/item-unit/create" component={CreateItemUnit}/>
                <Route path="/item-unit/list" component={ItemUnitList}/>
                <Route path="/item-unit/edit/:id" component={EditItemUnit}/>

                <Route path="/item/create" component={CreateItem}/>
                <Route path="/item/list" component={ItemList}/>
                <Route path="/item/edit/:id" component={EditItem}/>

                <Route path="/role/create" component={CreateRole}/>
                <Route path="/role/list" component={RoleList}/>
                <Route path="/role/edit/:id" component={EditRole}/>

                <Route path="/purchase/create" component={PurchaseCreate}/>
                <Route path="/purchase/list" component={PurchaseList}/>
                <Route path="/purchase/edit/:id" component={PurchaseEdit}/>

                <Route path="/user/create" component={CreateUser}/>
                <Route path="/user/list" component={UserList}/>
                <Route path="/user/edit/:id" component={EditUser}/>

                <Route path="/report-remarks/create" component={CreateReportRemarks}/>
                <Route path="/report-remarks/list" component={ReportRemarksList}/>
                <Route path="/report-remarks/edit/:id" component={EditReportRemarks}/>

                <Route path="/report-create/:mr_number" component={ReportCreate}/>
                <Route path="/patients-without-report" component={PatientsWithoutReport}/>
                <Route path="/patients-without-sample-received" component={PatientsWithoutSampleReceived}/>
                <Route path="/patients-with-report" component={PatientsWithReport}/>
                <Route path="/report-edit" component={ReportEdit}/>
                <Route path="/report-generate/:mr?" component={ReportGenerate}/> {/*//OPTIONAL PARAMETER*/}
                <Route path="/patients-report-bulk-update" component={PatientsReportBulkUpdate}/>
                <Route path="/create-bulk-auto-report-csv" component={CreateBulkAutoReportCsv}/>
                <Route path="/create-immuno-report-csv" component={CreateImmunoReportCsv}/>
                <Route path="/create-maglumi-report-csv" component={CreateMaglumiReportCsv}/>

                <Route path="/upload-pdf-result/:sample_id?" component={UploadPdfResult}/>
                <Route path="/report-update/:mr_number" component={ReportUpdate}/>
                <Route path="/get-unregistered-samples" component={UnregisteredSamples}/>

                {/* <Route path="/invoice" component={Invoice}/>
                <Route path="/report" component={Report}/>*/}
                <Route path="/item-consumption/create/:type" component={CreateItemConsumption}/>
                <Route path="/item-consumption/list/:type" component={ItemConsumptionList}/>
                <Route path="/item-consumption/mypdf" component={Mypdf}/>
                <Route path="/item-consumption/react-pdf" component={UmairHatePdf}/>

                <Route path="/lab/edit/:id" component={EditLab}/>

                <Route path="/transaction-category/create/:type" component={CreateTransactionCategory}/>
                <Route path="/transaction-category/list/:type" component={TransactionCategoryList}/>
                <Route path="/transaction-category/edit/:id/:type" component={EditTransactionCategory}/>

                <Route path="/transaction/create/:type" component={CreateTransaction}/>
                <Route path="/transaction/list/:type" component={TransactionList}/>
                <Route path="/transaction/edit/:id/:type" component={EditTransaction}/>

                {/*Summaries Section*/}
                <Route path="/summary/patient" component={Patient}/>
                <Route path="/summary/reference" component={Reference}/>
                <Route path="/summary/status" component={Status}/>
                <Route path="/summary/due-amount" component={DueAmount}/>
                <Route path="/summary/rate-list" component={RateList}/>
                <Route path="/summary/patient-activity-log" component={PatientActivityLog}/>
                <Route path="/summary/purchase-report" component={PurchaseReport}/>
                <Route path="/summary/purchase-summary" component={PurchaseSummary}/>
                <Route path="/summary/test-count" component={TestCount}/>
                <Route path="/summary/income-report" component={Income}/>
                <Route path="/summary/expense-report" component={Expense}/>
                <Route path="/summary/patient-excel" component={PatientExcel}/>

                <Route path="/faq/create" component={FaqCreate}/>
                <Route path="/faq/list" component={FaqList}/>
                <Route path="/faq/edit/:id" component={FaqEdit}/>

                <Route path="/menu/create" component={MenuCreate}/>
                <Route path="/menu/list" component={MenuList}/>
                <Route path="/menu/edit/:id" component={MenuEdit}/>
                <Route path="/menu/roles" component={MenuRoleEdit}/>

                <Redirect to="error/error-v1"/>

            </Switch>
        </Suspense>
    );
}
