export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        config => {
            const {
                auth: {authToken}
            } = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );

    // 2. Response Interceptor
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                // Optionally clear auth state or dispatch a logout action here
                // store.dispatch(logoutAction());
                if (!error.config.url.includes('/auth/login')) {
                    window.location.href = "/logout";
                }
            }
            return Promise.reject(error);
        }
    );
}
