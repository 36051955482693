import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";


import {API_URL} from '../constants.js';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import SettingsIcon from '@material-ui/icons/Settings';

export function TestList(props) {
    const {t} = useTranslation();
    const [tests, setTests] = useState([]);
    const [test, setTest] = useState(-1);
    const [testObject, setTestObject] = useState(-1);
    const [show, setShow] = useState(false);
    const [showHideModal, setShowHideModal] = useState(false);
    const [showAssignItemsModal, setShowAssignItemsModal] = useState(false);
    const history = useHistory();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const [itemTypes, setItemTypes] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});

    const setCurrentTest = (test_id, type) => {
        setTest(test);
        setTestObject(tests.find(t => t.id == test_id));
        if (type == 'delete') {
            setShow(true);
        } else if (type == 'enable-disable') {
            setShowHideModal(true);
        } else {

            let test = tests.find(t => t.id == test_id);
            let alreadySelectedItems = {};
            if (test.items && test.items.length > 0) {
                for (let item of test.items) {
                    alreadySelectedItems[item.item.item_type_id] = item.item_id;
                }
            }

            setSelectedItems(alreadySelectedItems);

            setShowAssignItemsModal(true);
        }
    };

    const handleClose = () => {
        setShow(false);
        setShowHideModal(false);
        setShowAssignItemsModal(false);
    }

    const deleteTest = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'test/' + test + '/delete'
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    const showHideTest = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'test/' + test + '/show-hide'
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    const handleItemSelection = (item_type_id, value) => {
        selectedItems[item_type_id] = value;
    }

    const saveTestItems = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'test/save-test-items', {
                'id': testObject.id,
                items: selectedItems
            }
        ).then((response) => {
            if (response.data.status) {
                // history.go(0);
                setShowAssignItemsModal(false);
                alertify.success(response.data.message);
                setDisabled(0);
                setIconDisabled(1);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    useEffect(() => {

        axios.get(API_URL + 'test').then((response) => {
            if (response.data.status) {

                setTests(response.data.data);

                $("#test_table").DataTable();
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

        axios.get(API_URL + 'item-type/get-by-mode?mode=TEST').then((response) => {
            if (response.data.status) {

                setItemTypes(response.data.data);
                console.log(response.data.data);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

    }, []);


    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Test List")}>
                            <CardHeaderToolbar>
                                <Link to="/test/create" className="btn btn-primary btn-sm mx-3">
                                    {t("Create Test")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody style={{overflow: 'auto'}}>

                            <table id="test_table" className="stratprop_datatable table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>{t("SN.")}</th>
                                    <th>{t("Code")}</th>
                                    <th>{t("Name")}</th>
                                    <th>{t("Short Name")}</th>
                                    <th>{t("Category")}</th>
                                    <th>{t("Turnaround Time")}</th>
                                    <th>{t("Amount")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tests.length > 0 ? (
                                    tests.map((test, index) => (
                                        <tr key={`test-` + test.id}>
                                            <td>{index + 1}</td>
                                            <td>{test.code}</td>
                                            <td>{test.name}</td>
                                            <td>{test.short_name}</td>
                                            <td>{test.test_category.name}</td>
                                            <td>{test.turn_around_time}</td>
                                            <td>{test.amount}</td>
                                            <td>
                                                <Link to={`/test/edit/${test.id}`}
                                                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                          <SVG
                                                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                          />
                                                    </span>
                                                </Link>
                                                <a
                                                    onClick={() => setCurrentTest(test.id, 'assign-items')}
                                                    key={`test-assign-items-` + test.id}
                                                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                    title={"Show on register page"}
                                                >
                                                    <SettingsIcon color={'primary'}></SettingsIcon>
                                                </a>
                                                <a
                                                    onClick={() => setCurrentTest(test.id, 'delete')}
                                                    key={"edit-test-" + test.id}
                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm mr-2">
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                                    </span>
                                                </a>

                                                {
                                                    test.external_list == 1 ? (
                                                        <a
                                                            onClick={() => setCurrentTest(test.id, 'enable-disable')}
                                                            key={`enable-disable-test-` + test.id}
                                                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                                            title={"Remove from register page"}
                                                        >
                                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                                              <SVG
                                                                  src={toAbsoluteUrl("/media/svg/icons/Home/Earth.svg")}
                                                                  title={"Remove on register page"}></SVG>
                                                            </span>
                                                        </a>
                                                    ) : (
                                                        <a
                                                            onClick={() => setCurrentTest(test.id, 'enable-disable')}
                                                            key={`enable-disable-test-` + test.id}
                                                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                            title={"Show on register page"}
                                                        >
                                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                              <SVG
                                                                  src={toAbsoluteUrl("/media/svg/icons/Home/Earth.svg")}
                                                                  title={"Show on register page"}></SVG>
                                                            </span>
                                                        </a>

                                                    )
                                                }

                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8}>{t("No Test")}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Delete Test")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("Are you sure you want to Delete?")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={deleteTest} disabled={disabled}>
                        {t("Confirm")}
                        <span
                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showHideModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Show/Hide Test on register page")}</Modal.Title>
                </Modal.Header>
                {
                    testObject.external_list == 1 ? (
                        <Modal.Body>{t("Are you sure you want to remove the test from register page?")}</Modal.Body>
                    ) : (
                        <Modal.Body>{t("Are you sure you want to show the test on register page?")}</Modal.Body>
                    )
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={showHideTest} disabled={disabled}>
                        {t("Confirm")}
                        <span
                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAssignItemsModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Assign Items")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        {
                            itemTypes.length > 0 ? (
                                itemTypes.map((itemType, index) => (
                                    <Form.Group as={Col} md={'12'}>
                                        <Form.Label>{itemType.name}</Form.Label>
                                        <Form.Control as="select"
                                                      onChange={(e) => handleItemSelection(itemType.id, e.target.value)}
                                                      defaultValue={(selectedItems && selectedItems[itemType.id] !== undefined) ? selectedItems[itemType.id] : "0"}
                                        >
                                            <option value={"0"}>{t("No Item")}</option>
                                            {itemType.items.length > 0 ? (
                                                itemType.items.map((item, index) => (
                                                    <option key={`item-type-` + itemType.id + `-item-id-` + index}
                                                            value={item.id}>{item.name}</option>
                                                ))
                                            ) : (
                                                ''
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                ))
                            ) : ('')
                        }

                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={saveTestItems} disabled={disabled}>
                        {t("Save")}
                        <span
                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}